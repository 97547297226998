// BOOTSTRAP 4 VARIABLES

@import "components/bootstrap4/functions";
@import "components/bootstrap4/variables";
@import "components/bootstrap4/mixins";

// ArchitectUI Themes Variables

@import "themes/layout-variables";

// BOOTSTRAP 4 IMPORTS

@import "components/bootstrap4/root";
@import "components/bootstrap4/reboot";
@import "components/bootstrap4/type";
@import "components/bootstrap4/images";
@import "components/bootstrap4/code";
@import "components/bootstrap4/grid";
@import "components/bootstrap4/tables";
@import "components/bootstrap4/forms";
@import "components/bootstrap4/buttons";
@import "components/bootstrap4/transitions";
@import "components/bootstrap4/dropdown";
@import "components/bootstrap4/button-group";
@import "components/bootstrap4/input-group";
@import "components/bootstrap4/custom-forms";
@import "components/bootstrap4/nav";
@import "components/bootstrap4/navbar";
@import "components/bootstrap4/card";
@import "components/bootstrap4/breadcrumb";
@import "components/bootstrap4/pagination";
@import "components/bootstrap4/badge";
@import "components/bootstrap4/jumbotron";
@import "components/bootstrap4/alert";
@import "components/bootstrap4/progress";
@import "components/bootstrap4/media";
@import "components/bootstrap4/list-group";
@import "components/bootstrap4/close";
@import "components/bootstrap4/toasts";
@import "components/bootstrap4/modal";
@import "components/bootstrap4/tooltip";
@import "components/bootstrap4/popover";
@import "components/bootstrap4/carousel";
@import "components/bootstrap4/spinners";
@import "components/bootstrap4/utilities";
@import "components/bootstrap4/print";

// LAYOUT

@import "layout/layout";

// UTILS

@import "utils/helpers";
@import "utils/backgrounds";
@import "utils/animate";
@import "utils/comps-animations";

// ELEMENTS

@import "elements/buttons";
@import "elements/dropdown";
@import "elements/navs";
@import "elements/badges";
@import "elements/cards";
@import "elements/tabs";
@import "elements/accordions";
@import "elements/modals";
@import "elements/loaders";
@import "elements/progressbar";
@import "elements/timeline";
@import "elements/listgroup";
@import "elements/forms";
@import "elements/pagination";
@import "elements/chat";
@import "elements/forms-wizard";

// DASHBOARD BOXES

@import "widgets/chart-boxes/chart-boxes";
@import "widgets/content-boxes/content-boxes";
@import "widgets/profile-boxes/profile-boxes";
// PAGES
@import "pages/userpages";
// Applications
@import "applications/applications-base";
// COMPONENTS
// Perfect Scrollbar
@import "components/perfect-scrollbar/perfect-scrollbar";

// FontAwesome
@import "components/icons/fontawesome/fontawesome";
// Ionicons
@import "components/icons/ionicons/ionicons";
// FlagKit
@import "components/icons/flagkit/flagkit";
// Image Crop
@import "components/image-crop/image-crop";
// Guided Tours
@import "components/guided-tours/guided-tours";
// Loading buttons
@import "components/loading-buttons/loading-buttons";
// Drawer
@import "components/drawer/drawer";
// Hamburger button
@import "components/hamburgers/hamburgers";
// Notifications
@import "components/notifications/sweetalerts";
@import "components/notifications/toastr";
// Slick Carousel
@import "components/slick-carousel/slick/slick";
// Popovers & Tooltips
@import "components/popovers-tooltips/popovers-tooltips";
// Calendar
@import "components/calendar/calendar";
// Maps
@import "components/maps/vector-maps";
// Tree View
@import "components/tree-view/tree-view";
// Tables
@import "components/tables/datatables";
@import "components/tables/tables";
// Icons
@import "components/icons/linearicons";
@import "components/icons/p7icons";

// FORMS

@import "components/forms/datepicker/datepicker";
@import "components/forms/datepicker/daterangepicker";
@import "components/forms/rangeslider/rangeslider";
@import "components/forms/multiselect/multiselect";
@import "components/forms/dropdown-select/dropdown-select";
@import "components/forms/wysiwyg/wysiwyg";
@import "components/forms/toggle-switch/switch";
// Responsive
@import "layout/responsive/responsive-base";
// DEMO
@import "demo-ui/demo";

// Gridstack
@import "~gridstack/dist/gridstack.css";

// Custom
@import "layout/custom";
