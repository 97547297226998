.wrap-external-icon {
    display: inline-block;
    .external-icon {
        display: block;
        width: 30px;
        height: 30px;
        position: relative;
        background-size: cover;
    }
}


.icon-invision {
    background-image: url('../images/icons/invision.svg');
}

.icon-quickbooks {
    background-image: url('../images/icons/quickbooks.png');
}

.btn-more:after {
    display: none;
}

.select2.select2-container.select2-container--bootstrap4 {
    margin-bottom: 0.5rem !important;
}

.grid-stack {
    .dropdown-menu {
        min-width: 17rem;
    }
}

.competition-podium {
    height: 200px;
    position: relative;
}

.competition-podium .podium-block {
    width: 80px;
    text-align: left;
    display: inline-block;
    position: absolute;
    bottom: 0;
}

.competition-podium .podium-block .place {
    font-size: 14px;
    font-weight: bold;
    line-height: normal;
    text-align: center;
    display: block;
}

.competition-podium .podium-block .sum {
    font-size: 12px;
    font-weight: bold;
    line-height: normal;
    white-space: nowrap;
    text-align: center;
    display: block;
}

.competition-podium .podium-block .name {
    font-size: 12px;
    font-weight: normal;
    line-height: 14px;
    display: block;
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 5px;
    z-index: 10;
    opacity: 1;
    padding: 2px;
}

.competition-podium .podium-block .name a {
    display: block;
}

.competition-podium .podium-block.bronze {
    color: #e6d6bf;
    left: 170px;
}

.competition-podium .podium-block.bronze .podium {
    background: #e6d6bf;
    height: 0px;
}

.competition-podium .podium-block.bronze .name a {
    color: #856e4e;
}

.competition-podium .podium-block.gold {
    color: #ead679;
    left: 85px;
}

.competition-podium .podium-block.gold .podium {
    background: #ead679;
    height: 0px;
}

.competition-podium .podium-block.gold .name a {
    color: #978325;
}

.competition-podium .podium-block.silver {
    color: #cbd9de;
    left: 0;
}

.competition-podium .podium-block.silver .podium {
    background: #cbd9de;
    height: 0px;
}

.competition-podium .podium-block.silver .name a {
    color: #5e818e;
}

.ui-sortable-handle {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
}

.grid-stack .select2-container {
    width: 100% !important;
}

.grid-stack .select2-container--bootstrap4 .select2-selection--single {
    height: auto;
    padding: 5px;
}

.grid-stack .card-header {
    text-transform: none;
}

.grid-stack .dropdown-menu .dropdown-item {
    align-items: normal;
}

.grid-stack .form-control {
    font-size: x-small;
    height: auto;
}

.grid-stack .select2.select2-container.select2-container--bootstrap4 {
    margin: 0 !important;
}

.bg-gray {
    background-color: #f1f4f6;
}

.btn-sm .badge-pill {
    right: -8px;
}

.btn-sm .badge {
    padding: 5px 2px;
}

.user-widget-links .btn {
    padding: 0;
}

.mw-0 {
    min-width: 0 !important;
}